<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-5">
        <div class="card card-custom shadow">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 my-5">
                <div class="mx-4">
                  <h4 class="text-center fw-bolder text-navy-blue">
                    Your location is serviceable.
                  </h4>

                  <div class="text-center my-5">
                    How will you be paying the installation fee?
                  </div>
                  <!-- 
                  <div class="text-center mt-3">
                    Application Reference Number
                    <div class="reference-number">{{ refNumber }}</div>
                  </div> -->

                  <div class="text-center">
                    <button
                      class="btn px-3 btn-jungle-green btn-300"
                      @click.prevent="goCod(true)"
                    >
                      COD (Cash on Delivery)
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <data-pack-modal ref="dataPackModal"></data-pack-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import DataPackModal from "../components/DataPackModal.vue";

export default {
  components: { DataPackModal },

  data() {
    return {
      modalMaps: {
        isShow: false,
      },

      refNumber: "",
      submitted: false,
    };
  },

  methods: {
    goBack() {
      this.$store.dispatch("ADD_PREDATA", null);
      this.$store.dispatch("ADD_AFFILIATECODE", "");
      this.$store.dispatch("ADD_APPLICATION_FORM", false).then(() => {
        this.$router.push({ path: "/" });
      });
    },

    goToApplicationForm(isCod) {
      this.$store.dispatch("ADD_IS_COD", isCod);
      this.$store.dispatch("ADD_APPLICATION_FORM", true).then(() => {
        this.$router.push({ path: "application-form" });
      });
    },

    reFormatAccountNumber(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },

    async goCod(force = false) {
      await this.$refs.dataPackModal.open(force, true).then((result) => {
        if (result.isCod) {
          // this.$router.push({ path: "application-form" });
          this.$store.dispatch("ADD_IS_COD", true).then(() => {
            this.goToApplicationForm(true);
          });
        }
      });
    },
  },

  created() {
    // const refNumber = this.$store.getters.GET_REFNUMBER;
    // if (refNumber) {
    //   this.refNumber = refNumber;
    // } else {
    //   this.$router.push({ path: "/" });
    // }
    //
    const appData = this.$store.getters.GET_PREDATA;
    const isEmpty = Object.keys(appData).length === 0;
    console.log(!isEmpty);
    if (isEmpty) {
      this.$router.push({ path: "/" });
      return;
    }
  },

  computed: {
    GET_PREDATA() {
      return this.$store.getters.GET_PREDATA;
    },
    applicationData() {
      return this.$store.getters.GET_APPLICATIONFORMDATA;
    },

    getDataPack() {
      return this.$store.getters.GET_DATAPACK;
    },

    getRefNumber() {
      return this.$store.getters.GET_REFNUMBER;
    },

    getAffiliateCode() {
      return this.$store.getters.GET_AFFILIATECODE;
    },
  },

  validations: {
    accountNumber: {
      required,
    },
  },
};
</script>

<style scoped>
.btn-300 {
  width: 300px;
}
/* @import "../assets/css/reset.css";
@import "../assets/css/sweetalert2.min.css";
@import "../assets/css/superwheel.min.css"; */
</style>
