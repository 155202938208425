<template>
  <div
    class="modal fade modal-data-pack"
    ref="modalDataPack"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="mobileNumberModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header pb-1">
          <h6 class="text-blue">Data Pack</h6>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 my-4">
              <h6 class="text-center">
                Please select the Data pack you would like to avail?
              </h6>
            </div>
            <div class="col-12">
              <div
                class="price-item-border shadow-sm"
                :class="{ active: isActive.skuId == sku.skuId }"
                v-for="(sku, i) in skus"
                :key="i"
                @click.prevent="selectSku(sku)"
              >
                <div class="row">
                  <div class="col-9 p-2 pl-2">
                    <div class="rate-container">
                      <div>
                        <div class="rate-title">{{ sku.skuName }}</div>
                        <div class="rate-description">
                          {{ sku.description }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 d-flex align-items-center p-2">
                    <div class="price-item" :class="background">
                      <div>
                        <div class="currency">PHP</div>
                        <div class="price">{{ sku.price }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button
            type="button"
            class="btn btn-s2s-blue"
            @click.prevent="appendMobileNumber()"
          >
            Submit
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
  data() {
    return {
      background: "bg-f30",
      modal: null,
      resolve: null,
      reject: null,

      modals: {
        mobileNumber: null,
      },

      skus: [],
      selectedSku: {},

      mobileNumber: "",
      submitted: false,

      isCod: false,
    };
  },
  methods: {
    selectSku(item) {
      this.$store
        .dispatch("ADD_DATAPACK", item)
        .then(() => {
          if (this.isCod) {
            this.resolve({ status: true, isCod: this.isCod });
            this.modal.hide();
            return;
          }
          this.modal.hide();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    open(force, isCod = false) {
      const sku = this.$store.getters.GET_DATAPACK;

      if (Object.keys(sku).length === 0) {
        // this.getLocation();
        // console.log("Asdasda");
        this.modal.show();
      }

      if (force) {
        this.modal.show();
      }

      this.isCod = isCod;

      // this.title = title;
      // this.message = message;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      // this.dialog = false;
      this.modal.hide();
    },
    cancel() {
      this.resolve(false);
      // this.dialog = false;
      this.modal.hide();
    },

    async getSKUs() {
      await this.$api3
        .get("/acquisition/sku")
        .then((response) => {
          const res = response.data;
          // 710000047
          // 710000046
          // const removeSku = ["710000047", "710000046"];
          const displaySKU = ["710000038"];

          this.skus = res.data.list.filter((item) =>
            displaySKU.includes(item.skuId)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalDataPack, {
      keyboard: false,
    });
  },

  computed: {
    isActive() {
      const sku = this.$store.getters.GET_DATAPACK;
      var def = { skuId: 0 };
      if (Object.keys(sku).length != 0) {
        return sku;
      }

      return def;
    },
  },

  created() {
    this.getSKUs();
  },
};
</script>
